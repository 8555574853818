<template>
  <div>
    <profile></profile>
  </div>
</template>

<script>
import {
  default as Profile
} from "@/components/widgets/Profile";
import { mapGetters } from 'vuex';
export default {
  name: 'WaiProfile',
  computed: mapGetters({
    lang: 'lang'
  }),
  components: {
    Profile
  },
  props: {},
  data () {
    return {};
  },
  created: function () {},
  beforeMount () {},
  mounted: function () {},
  methods: {},
  watch: {}
};

</script>

